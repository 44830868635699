import { each, map } from 'lodash'

// App
import { app } from './utils/store'

// User Detect
import { DetectionManager } from './utils/detect'

// Async Load
import AsyncLoad from './classes/AsyncLoad'


// Renderers
import Menu from './modules/Menu'
import Gallery from './modules/Gallery'

// Animations
import Title from './animations/Title'
import Paragraph from './animations/Paragraph'
import ScrollImages from './animations/ScrollImages'

class App{
    constructor(){
        app.root = document.body
        
        //Async Load
        app.images = document.querySelectorAll('img[data-src]')
        
        this.createContent()
        this.createNavigation()

        this.createGallery()

        this.createAnimations()
        this.createPreloaders()

        app.init = true
    }

    createContent(){
        //Async Load
        this.images = document.querySelectorAll('[data-src]')

        this.gallery = document.querySelector('ul[data-gallery]')

        //Animations
        this.animated = {
            titles: document.querySelectorAll('[data-animation="title"]'),
            paragraphs: document.querySelectorAll('[data-animation="paragraph"]'),
            scrollimages: document.querySelectorAll('[data-animation="boxes"]'),
            labels: document.querySelectorAll('[data-animation="label"]'),
        }
    }

    // Create Navigation
    createNavigation = () => {
        app.navigation = new Menu()
    }
    
    // Create Modals
    createModals() {
        each(this.modal.togglers, (toggler) => ModalManager.toggle(toggler))
        each(this.modal.closers, (closer) => ModalManager.close(closer))
    }

    createGallery() {
        if(this.gallery){ 
            this.gallery = new Gallery({ element: this.gallery }) 
        }
    }

    createAnimations() {
        this.animations = new Array()

        // Titles
        this.animationsTitles = map(this.animated.titles, (element) => {
            return new Title({ element })
        })
        this.animations.push(...this.animationsTitles)

        // Paragraphs
        this.animationsParagraphs = map(this.animated.paragraphs, (element) => {
            return new Paragraph({ element })
        })
        this.animations.push(...this.animationsParagraphs)

        // ScrollImages
        this.animationsScrollImages = map(this.animated.scrollimages, (element) => {
            return new ScrollImages({ element })
        })
        this.animations.push(...this.animationsScrollImages)

        // Labels
        this.animationsLabels = map(this.animated.labels, (element) => {
            return new Label({ element })
        })
        this.animations.push(...this.animationsLabels)
    }

    createPreloaders(){
        this.preloaders = map(app.images, element => {
            return new AsyncLoad({ element })
        })
    }
}

new App()