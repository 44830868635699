import Component from "../classes/Component";

export default class Animation extends Component{
    constructor({ element, elements }){
        super({ 
            element, 
            elements
        });

        this.createObserver();

        this.animateOut();
    }

    createObserver() {
        this.observer = new window.IntersectionObserver( (entries) => {
            entries.forEach((entry) => {
                if(entry.isIntersecting){ // If is in viewport
                    this.animateIn();
                } else {
                    this.animateOut();
                }
            })
        })

        this.observer.observe(this.element)
    }

    animateIn() {
        // That will be ovverride from animation/*.js
    }

    animateOut() {
        // That will be ovverride from animation/*.js
    }

    onResize() {
        // That will be ovverride from animation/*.js
    }
}