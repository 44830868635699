import Component from './Component';
import { app } from '../utils/store';

import each from 'lodash/each';

export default class AsyncLoad extends Component {
	constructor({ element }) {
		super({ element })

		this.createObserver()
	}

	createObserver(element) {
		this.observer = new window.IntersectionObserver((entries) => {
			each(entries,(entry) => {
				if (entry.isIntersecting) {
					this.element.src = app.webp === true ? this.element.getAttribute('data-webp') : this.element.getAttribute('data-src')
					this.element.onload = () => {
						this.element.setAttribute('loaded', '');
					}
				}
			})
		})

		this.observer.observe(this.element)
	}
}
