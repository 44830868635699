import Animation from "../classes/Animation"

import each from 'lodash/each';
import gsap from 'gsap';

import { calculate, split } from '../utils/text'
let viewportHeight = window.innerHeight;
let viewportWidth = window.innerWidth;

export default class Paragraph extends Animation {
    constructor({ element }){
        super({
            element
        })

        this.elementLines = split({ element: this.element, append: true });
    }

    animateIn(){
        this.timelineIn = gsap.timeline({
            delay: .5,
        });

        this.timelineIn.set(this.element, {
            autoAlpha: 1
        })

        each(this.elementLines, (line, index) =>{
            this.timelineIn.fromTo(line, {
                autoAlpha: 0,
                y: '100%',
            },{
                delay: index * .005,
                duration: 1.5,
                ease: 'expo.out',
                autoAlpha: 1,
                y: 0
            }, 0)
        })
    }

    animateOut(){
        gsap.set(this.element, {
            autoAlpha: 0
        })
        // each(this.elementLines, (line, index) =>{
        //     this.timelineIn.set(line, {
        //         autoAlpha: 0,
        //         y: '100%'
        //     })
        // })
    }

    onResize() {
        this.elementLines = calculate(this.elementLines)
    }

}
