import { each } from 'lodash'
import GLightbox from 'glightbox'

export default class Gallery {
    constructor({ element }) {
        this.id = element.id

        this.element = {
            images:  document.querySelectorAll(`#${this.id} img`),
            lightbox: document.getElementById(this.id)
        }

        this.create()
    }

    create = () => {
        this.elements = new Array()

        each(this.element.images, (image) => {
            this.elements.push({
                'href' : image.getAttribute('data-src'),
                'type' : 'image',
                'title' : image.title ? image.title : null,
                'description' : image.nextElementSibling ? image.nextElementSibling.innerText : null,
                'alt' : image.alt ? image.alt : null
            })
        })
        
        this.lightbox = GLightbox({ elements: this.elements })

        each(this.element.images, (image) => {
            image.parentNode.onclick = (event) => { this.lightbox.openAt(image.getAttribute('loop')) }
        })
    }
}