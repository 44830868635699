import Animation from "../classes/Animation"

import each from 'lodash/each'
import gsap from 'gsap'


export default class ScrollImages extends Animation {
    constructor({ element }){
        super({
            element
        })

        this.ScrollImages()
        console.log("We are on Scroll Images")
    }

    ScrollImages(){
        // var colors = ["#f38630","#6fb936", "#ccc", "#6fb936"]
        var wrap = gsap.utils.wrap(-1000, 2000)
        
        console.log("wrap: ", wrap)


        //initially colorize each box and position in a row
        gsap.set(".boxes", {
        //   backgroundColor: (i) => colors[i % colors.length],
          y: (i) => i * 500
        })
        
        
        gsap.to(".boxes", {
          duration: 15,
          ease: "none",
          y: "-=3000", //move each box 500px to right
          modifiers: {
            y: gsap.utils.unitize(wrap) //force y value to wrap when it reaches -100
          },
          repeat: -1
        })
        
        
        //toggle overflow
        const overflow = document.querySelector("#overflow")

        console.log("Overflow", overflow)

        overflow.addEventListener("change", applyOverflow)
        
        function applyOverflow() {
          if(overflow.checked) {
            gsap.set(".wrapper", {overflow: "visible"})
          } else {
            gsap.set(".wrapper", {overflow: "hidden"})
          }
        }
    }
}
