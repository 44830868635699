import {each} from 'lodash'

export default class Menu {
    constructor() {
        this.menu = {
            toggler: document.getElementById('menu-toggler'),
            main: document.getElementById('main-menu'),
            body: document.querySelector('body'),
            menuc: document.getElementById('containermenu'),
            item: document.querySelectorAll('#main-menu .menu-item'),
            menuitem: document.querySelectorAll('#main-menu .mitem'),
            imgdefault: document.getElementById('img-default'),
            slider: document.querySelectorAll('.h-section'),
            logo: document.querySelectorAll('#navbar-animation a.logo svg g path'),
            logoactive: document.querySelector('.header'),
            mobile: document.querySelector('.menu-mobile'),
        }

        this.lang = {
            toggler: document.querySelector('#select-language .dropdown-toggler'),
            menu: document.querySelector('#select-language .dropdown-menu'),
        }
        
        this.toggle()
        this.hoverMenu()
        this.hoverSlider()
        if (this.lang.toggler) { this.language() }
    }

    toggle = () => {
        this.menu.toggler.addEventListener('click', () => {
            this.menu.main.classList.toggle('active')
            this.menu.toggler.classList.toggle('active')
            this.menu.mobile.classList.toggle('active')
            this.menu.body.classList.toggle('no-scroll')
            this.menu.logoactive.classList.toggle('logo-active')

            if (this.lang.toggler) {
                this.lang.menu.classList.remove('show')
            }
        })
    }

    hoverMenu = () => {

        if (this.menu.item) {
            each(this.menu.item, (item) => {
                item.addEventListener('mouseenter', () => {
                    this.menu.menuc.setAttribute("style", "background-color: " + item.dataset.bgcolor + ";")
                    this.menu.imgdefault.setAttribute("style", "display: none")
                    item.classList.toggle('active')

                    each(this.menu.menuitem, (e) => {
                        e.setAttribute("style", "color: " + item.dataset.color + ";")
                    })



                })
                item.addEventListener('mouseleave', () => {
                    this.menu.menuc.removeAttribute("style", "background-color: " + "initial" + ";")
                    item.removeAttribute("style", "color: " + "initial" + ";")
                    item.classList.remove('active')
                    this.menu.imgdefault.setAttribute("style", "display: block")

                    each(this.menu.menuitem, (e) => {
                        e.removeAttribute("style", "color: " + "inital" + ";")
                    })
                })
            })

        }
    }

    language = () => {
        this.lang.toggler.addEventListener('click', () => {
            this.lang.menu.classList.toggle('show')
        })
    }

    hoverSlider = () => {

        //console.log("LOGO: ", this.menu.logo)

        var countItem = this.menu.slider.length - 1;

        if (this.menu.slider) {
            each(this.menu.slider, (slider, index) => {
                slider.addEventListener('mouseover', () => {

                    //console.log("Index: ", index)
                    //console.log("countItem: ", countItem)

                    var title = slider.firstElementChild;
                    var type = title.dataset.type;
                    //slider.setAttribute("style", "color: " + slider.dataset.color + ";")

                })
            })

        }
    }
}
