import Animation from "../classes/Animation"

import each from 'lodash/each'
import gsap from 'gsap'


import { calculate, split } from '../utils/text'

export default class Title extends Animation {
    constructor({ element }){
        super({
            element
        })

        split({ element: this.element, append: true })
        split({ element: this.element, append: true })

        this.elementLines = this.element.querySelectorAll('span span')
    }

    animateIn(){
        this.element.classList.add('in-view')

        this.timelineIn = gsap.timeline({
            delay: .5,
        })

        // this.timelineIn.set(this.element, {
        //     autoAlpha: 1
        // })

        each(this.elementLines, (line, index) =>{
            this.timelineIn.fromTo(line, {
                // autoAlpha: 0,
                y: '100%',
            },{
                delay: index * .025,
                duration: 1.5,
                ease: 'expo.out',
                // autoAlpha: 1,
                y: 0
            }, 0)
        })
    }

    animateOut(){
        this.element.classList.remove('in-view')

        // gsap.set(this.element, {
        //     autoAlpha: 0
        // })
        // each(this.elementLines, (line, index) =>{
        //     this.timelineIn.set(line, {
        //         y: '100%'
        //     })
        // })
    }

    onResize() {
        this.elementLines = calculate(this.elementLines)
    }
}
