import { breakpoints, operators } from './store';

export function media(expression1, expression2 = null) {
    var media = window.matchMedia(media_expression(expression1, expression2))

    return media.matches
}

export function media_expression(expression1, expression2 = null) {
    let template = null
    
    Object.keys(breakpoints).forEach((breakpoint)=> {
        let value = breakpoints[breakpoint];
        if(expression1.indexOf('=') == -1){
            if(expression1.indexOf('>') == -1){
                value = value - 1
            } else if (expression1.indexOf('<') == -1){
                value = value + 1
            }
        }
        expression1 = expression1.replace(new RegExp(breakpoint,'g'), value + 'px')
    })

    Object.keys(operators).forEach((operator)=> {
        expression1 = expression1.replace(new RegExp(operator,'g'), operators[operator])
    })

    if(expression2 != null){
        Object.keys(breakpoints).forEach((breakpoint)=> {
            let value = breakpoints[breakpoint];
            if(expression2.indexOf('=') == -1){
                if(expression2.indexOf('>') == -1){
                    value = value - 1
                } else if (expression2.indexOf('<') == -1){
                    value = value + 1
                }
            }
            expression2 = expression2.replace(new RegExp(breakpoint,'g'), value + 'px')
        })
    
        Object.keys(operators).forEach((operator)=> {
            expression2 = expression2.replace(new RegExp(operator,'g'), operators[operator])
        })
    }

    if (expression2 === null) {
        return `(${expression1})`
    } else {
        return `(${expression1}) and (${expression2})`
    }
}